<template>
  <div class="main">
    <h2>{{ $t('msg.zone.title') }}</h2>
    <p class="my-4">{{ $t('msg.zone.subtitle') }}</p>
    <div class="card">
      <div class="card-body px-5 py-3">
        <a-list
          size="small"
          :data-source="results.data">
          <a-list-item
            slot="renderItem"
            slot-scope="item">
            <a-list-item-meta>
              <h5 slot="title">
                {{ item.meta.city || item.meta.state }}
              </h5>
              <div slot="description" class="text-secondary">
                <div>
                  <a-icon type="environment" />
                  / {{ item.meta.country }} / {{ item.meta.state }} / {{ item.meta.city }}
                </div>
              </div>
            </a-list-item-meta>
            <a-tag class="text-uppercase">
              {{ $t('region') }} {{ item.data.region }}
            </a-tag>
            <a-tag class="text-uppercase">
              {{ $t('rate') }} {{ item.data.rate }}
            </a-tag>
          </a-list-item>
        </a-list>
      </div>
    </div>
  </div>
</template>
<script>
import lms from '@/api/lms'
export default {
  name: 'ZoneList',
  data: function () {
    return {
      pagination: {
        page: 1,
        size: 500,
      },
    }
  },
  computed: {
    results() {
      return this.$store.getters['operation/ZONES']
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      return lms.zone.search(this.pagination)
        .then(response => this.$store.commit('operation/SET_ZONES', response.data))
    },
  },
}
</script>
